export const getStyleTitle = (titleFormated, defaultTitle) => {
  if (titleFormated?.includes('título de página')) {
    return 'h1';
  }

  if (titleFormated?.includes('títuto de seção')) {
    return 'h2';
  }

  if (titleFormated?.includes('subtítulo')) {
    return 'h3';
  }

  return defaultTitle;
};
