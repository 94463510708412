import React from 'react';
import styled, { css } from 'styled-components';
import { getStyleTitle } from '@catalogo/service-americanas-zion-title';

const ZionTitle = ({ publication }) => {
  const { contentTitle, style, colorText } = publication;
  const titleFormated = style?.toLowerCase()?.trim();

  const titles = {
    h1: <Title>{contentTitle}</Title>,
    h2: <SessionTitle>{contentTitle}</SessionTitle>,
    h3: <SubTitle>{contentTitle}</SubTitle>,
  };

  const title = titles[getStyleTitle(titleFormated, 'h1')];
  return <Wrapper colorText={colorText}>{title}</Wrapper>;
};

const Wrapper = styled.div`
  h1,
  h2,
  h3 {
    ${({ theme, colorText }) => css`
      margin-bottom: 10px;
      text-transform: ${theme.titleTransform};
      font-weight: bold;
      color: ${colorText || theme.bgColor || theme.grey.dark};
    `}
  }
`;

const Title = styled.h1`
  font-size: 22px;
  line-height: 30px;

  @media (min-width: 680px) {
    font-size: 28px;
    line-height: 40px;
  }
`;

const SessionTitle = styled.h2`
  font-size: 18px;
  line-height: 24px;

  @media (min-width: 680px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const SubTitle = styled.h3`
  font-size: 20px;
  line-height: 24px;

  @media (min-width: 680px) {
    line-height: 28px;
  }
`;

export default ZionTitle;
